












































import { Vue, Component } from 'vue-property-decorator'
import AboutUsContent from '@/components/layout/AboutUsContent.vue'
import VerticalHeading from '@/components/typography/VerticalHeading.vue'
import SignUpBanner from '@/components/layout/SignUpBanner.vue'

@Component({
  components: {
    AboutUsContent,
    VerticalHeading,
    SignUpBanner
  }
})

export default class MemberBenefits extends Vue {
}

